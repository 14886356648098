export const truckNumberConstants = {
  GET_SEABIN_TRUCK_REQUEST: ' GET_SEABIN_TRUCK_REQUEST',
  GET_SEABIN_TRUCK_SUCCESS: 'GET_SEABIN_TRUCK_SUCCESS',
  GET_SEABIN_TRUCK_FAILURE: 'GET_SEABIN_TRUCK_FAILURE',

  GET_WAREHOUSE_TRUCK_REQUEST: ' GET_WAREHOUSE_TRUCK_REQUEST',
  GET_WAREHOUSE_TRUCK_SUCCESS: 'GET_WAREHOUSE_TRUCK_SUCCESS',
  GET_WAREHOUSE_TRUCK_FAILURE: 'GET_WAREHOUSE_TRUCK_FAILURE',

  GET_TRUCK_REQUEST: ' GET_TRUCK_REQUEST',
  GET_TRUCK_SUCCESS: 'GET_TRUCK_SUCCESS',
  GET_TRUCK_FAILURE: 'GET_TRUCK_FAILURE',
}
