import React from 'react'

const UserNoScreens = () => {
  return (
    <div className='container'>
      <h1 className='mt-5' style={{ textAlign: 'center' }}>
        Screen under maintenance.....{' '}
      </h1>
      {/* <div className='mt-5 row'>
        <button
          type='button'
          onClick={() => console.log('Button leads them to Contact Admin (via email?)')}
          className=' btn btn-primary'
        >
          Contact Admin
        </button>
      </div> */}
    </div>
  )
}

export default UserNoScreens
