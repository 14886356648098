export const bagStatusFlightConstants = {
  GET_BAG_STATUS_FLIGHT_REQUEST: ' GET_BAG_STATUS_FLIGHT_REQUEST',
  GET_BAG_STATUS_FLIGHT_SUCCESS: 'GET_BAG_STATUS_FLIGHT_SUCCESS',
  GET_BAG_STATUS_FLIGHT_FAILURE: 'GET_BAG_STATUS_FLIGHT_FAILURE',

  GET_CARRIER_CODE_REQUEST: ' GET_CARRIER_CODE_REQUEST',
  GET_CARRIER_CODE_SUCCESS: 'GET_CARRIER_CODE_SUCCESS',
  GET_CARRIER_CODE_FAILURE: 'GET_CARRIER_CODE_FAILURE',

  formValueBagStatusFlight: 'formValueBagStatusFlight',
}
