export const rowStyle = {
  fontSize: '0.82rem',
  borderRightColor: '#e2e2e2',
  //   height: '50px',
  display: 'flex',
  justifyContent: 'flex-start',
  alignItems: 'center',
  lineHeight: '110%',
}

export const groupStyle = {
  fontSize: '0.82rem',
  borderRightColor: '#e2e2e2',
  //   height: '50px',
  display: 'flex',
  justifyContent: 'flex-start',
  alignItems: 'center',
}
