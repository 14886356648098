export const maintainUserDataConstants = {
  GET_MAINTAIN_USER_DATA_REQUEST: 'GET_MAINTAIN_USER_DATA_REQUEST',
  GET_MAINTAIN_USER_DATA_SUCCESS: 'GET_MAINTAIN_USER_DATA_SUCCESS',
  GET_MAINTAIN_USER_DATA_FAILURE: 'GET_MAINTAIN_USER_DATA_FAILURE',

  GET_MAINTAIN_USER_ROLES_DATA_REQUEST: 'GET_MAINTAIN_USER_ROLES_DATA_REQUEST',
  GET_MAINTAIN_USER_ROLES_DATA_SUCCESS: 'GET_MAINTAIN_USER_ROLES_DATA_SUCCESS',
  GET_MAINTAIN_USER_ROLES_DATA_FAILURE: 'GET_MAINTAIN_USER_ROLES_DATA_FAILURE',

  POST_MAINTAIN_USER_DATA_REQUEST: 'POST_MAINTAIN_USER_DATA_REQUEST',
  POST_MAINTAIN_USER_DATA_SUCCESS: 'POST_MAINTAIN_USER_DATA_SUCCESS',
  POST_MAINTAIN_USER_DATA_FAILURE: 'POST_MAINTAIN_USER_DATA_FAILURE',
}
