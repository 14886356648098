export const mergePaxConstants = {
  GET_PAX_REQUEST: ' GET_PAX_REQUEST',
  GET_PAX_SUCCESS: 'GET_PAX_SUCCESS',
  GET_PAX_FAILURE: 'GET_PAX_FAILURE',

  POST_PAX_REQUEST: 'POST_PAX_REQUEST',
  POST_PAX_SUCCESS: 'POST_PAX_SUCCESS',
  POST_PAX_FAILURE: 'POST_PAX_FAILURE',

  GET_CARR_REQUEST: 'GET_CARR_REQUEST',
  GET_CARR_SUCCESS: 'GET_CARR_SUCCESS',
  GET_CARR_FAILURE: 'GET_CARR_FAILURE',
}
