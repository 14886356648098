export const TITLE_CREATE_ROLE = 'Create Role'
export const TITLE_MAINTAIN_ROLE = 'Maintain Role'
export const TITLE_CREATE_BIN = 'Create Bin Location'
export const TITLE_MAINTAIN_BIN = 'Maintain Bin Location'
export const TITLE_MAINTAIN_BANK_ACC = 'Maintain Bank Account'
export const TITLE_SAVING_ACC_NUMBER = 'Saving Account Number'
export const TITLE_BANK_DETAILS = 'Bank Details'
export const TITLE_BANK_HOLIDAYS = 'Bank Holidays'
export const TITLE_SECURITY_ACC = 'Security Account'
export const TITLE_CREATE_HOLIDAY = 'Create Holiday'
export const TITLE_MAINTAIN_HOLIDAYS = 'Maintain Holidays'
export const TITLE_CREATE_TRUCK = 'Create Truck'
export const TITLE_MAINTAIN_TRUCK = 'Maintain Truck'
export const TITLE_NOTIFICATIONS = 'Notifications'
export const TITLE_RESULTS = 'Results'
export const TITLE_DELIVERY_MANIFEST = 'Delivery Manifest'
export const TITLE_NACCS_PREVIEW = 'NACCS Preview'
export const TITLE_NACCS_FILE_GENERATION = 'NACCS File Generation'
export const TITLE_FILE_STATUS_ENQUIRY = 'NACCS File Status Enquiry'

export const MaintainReferenceData = 'Maintain Reference Data'
export const CreateCage = 'Create Cage'
export const CreateCarrier = 'Create Carrier'
export const MaintainCarrier = 'Maintain Carrier'
export const UpdateCage = 'Update Cage'
export const MaintainUsers = 'Maintain Users'
export const assignSeaBin = 'Assign Sea Bin'
export const mergePax = 'Merge Pax'
export const overrideBagtracking = 'Override Bagtracking'
export const releaseBags = 'Release Bags'
export const picklist = 'Picklist Generation'
export const stampDuty = 'Stamp Duty Report'
export const cartonTrackingEnquiry = 'Carton Tracking Enquiry'
export const cageTrackingEnquiry = 'Cage Tracking Enquiry'
export const bagTrackingEnquiry = 'Bag Tracking Enquiry'
export const deliveryManifest = 'Delivery Manifest'
export const bagTraxDailySummaryReport = 'BagTrax Daily Summary Report'
export const bagTraxDeliveryReportByTrip = 'BagTrax Delivery Report By Trip '
export const bagByBagStatusReport = 'Bag By Bag Status Report '
export const bagsInPickupCounterReport = 'Bags In Pickup Counter Report'
export const bagStatusFlight = 'Bags Status By Flight'
export const purchaseEnquiryByPax = 'Purchase Enquiry By Pax'
export const dpTobaccoReport = 'DP Tobacco Report'

export const LABEL_ROLE_NAME = 'Role Name'
export const LABEL_APPL_SCREENS = 'Applicable Screens'
export const LABEL_SELECT_SCREEN = 'Select Screen'
export const LABEL_LOCATION_TYPE = 'Location Type'
export const LABEL_BIN_LOCATION = 'Bin Location'
export const LABEL_CARTON_CAPACITY = 'Carton Capacity'
export const LABEL_CAGE_CAPACITY = 'Cage Capacity'
export const LABEL_PICKUP_LOCATION = 'Pickup Location'
export const LABEL_REMARKS = 'Remarks'
export const LABEL_ACC_NO = 'Account Number'
export const LABEL_BANK_NAME = 'Bank Name'
export const LABEL_BRANCH = 'Branch'
export const LABEL_JP_POSTAL_CODE = 'Japan Postal Code'
export const LABEL_ADDRESS_LINE_1 = 'Address Line 1'
export const LABEL_ADDRESS_LINE_2 = 'Address Line 2'
export const LABEL_CITY = 'City'
export const LABEL_COUNTRY = 'Country'
export const LABEL_DATE = 'Date'
export const LABEL_HOURS = 'Hours'
export const LABEL_MINUTES = 'Minutes'
export const LABEL_FLIGHT_ACC_NO = 'Flight Account Number'
export const LABEL_FLIGHT_ACC_DESC = 'Flight Account Description'
export const LABEL_VESSEL_ACC_NO = 'Vessel Account Number'
export const LABEL_VESSEL_ACC_DESC = 'Vessel Account Description'
export const LABEL_TRUCK_NO = 'Truck Number'
export const LABEL_MODEL = 'Model'
export const LABEL_MANUFACTURER = 'Manufacturer'
export const LABEL_YEAR = 'Year'
export const LABEL_MAKE = 'Make'
export const LABEL_NOTIFICATION_TYPE = 'Notification Type'
export const LABEL_ACTION_TYPE = 'Action Type'
export const LABEL_DEPARTURE_DATE_RANGE = 'Departure Date (Range)'
export const LABEL_DEPARTURE_DATE = 'Departure Date'
export const LABEL_PICKUP_COUNTER = 'Pickup Counter'
export const LABEL_INTERNATIONAL = 'International'
export const LABEL_NON_ACKNOWLEDGED = 'Non-Acknowledged'
export const LABEL_NON_RECONCILED = 'Non-Reconciled'

export const TEXT_ROLENAME = 'Enter Role Name'
export const TEXT_BIN_LOCATION = 'Bin Location'
export const TEXT_PICKUP_LOCATION = 'Select Pickup Location'
export const TEXT_CARTON_CAPACITY = 'Enter Carton Capacity (Number)'
export const TEXT_CAGE_CAPACITY = 'Enter Cage Capacity (Number)'
export const TEXT_ADD_REMARKS = 'Additional Remarks'
export const TEXT_HOLIDAY_TYPE = 'e.g. Holiday Type'
export const TEXT_DATE_FORMAT = 'YYYY-MM-DD'
export const TEXT_HOLDING_LOCATION = 'Holding Location'
export const TEXT_SEARCH_BY = 'Search By…'
export const TEXT_SEARCH_REMARKS = 'Search Remarks'
export const TEXT_SELECT = 'Select…'
export const TEXT_ACC_NO = 'Enter Account Number'
export const TEXT_BANK_NAME = 'Enter Bank Name'
export const TEXT_BRANCH = 'Enter Branch Name'
export const TEXT_ADDRESS = 'Enter Address'
export const TEXT_POSTAL_CODE = 'Enter Postal Code'
export const TEXT_CITY = 'Enter City'
export const TEXT_COUNTRY = 'Enter Country'
export const TEXT_TRUCK_NO = 'Enter Truck Number'
export const TEXT_MODEL = 'Enter Model'
export const TEXT_MANUFACTURER = 'Enter Manufacturer'
export const TEXT_YEAR = 'Enter Year'
export const TEXT_MAKE = 'Enter Make'
export const TEXT_MAINTAIN_TRUCK_SEACH = 'Search Truck Number, Model, Manufacturer, Year or Make'
export const TEXT_VIEW_DATA_FROM = 'View Flight/NACCS data from...'
export const TEXT_CONNECT_ERROR = 'Unable to retrieve data from the backend service'

export const BUTTON_SUBMIT = 'Submit'
export const BUTTON_SEARCH = 'Search'
export const BUTTON_SAVE = 'Save'
export const BUTTON_CANCEL = 'Cancel'
export const BUTTON_GO = 'Go'
export const BUTTON_ADD_NEW = 'Add New'
export const BUTTON_PRINT = 'Print'
