export const maintainReferenceDataConstants = {
  GET_MAINTAIN_REFERENCE_DATA_REQUEST: 'GET_MAINTAIN_REFERENCE_DATA_REQUEST',
  GET_MAINTAIN_REFERENCE_DATA_SUCCESS: 'GET_MAINTAIN_REFERENCE_DATA_SUCCESS',
  GET_MAINTAIN_REFERENCE_DATA_FAILURE: 'GET_MAINTAIN_REFERENCE_DATA_FAILURE',

  GET_MAINTAIN_REFERENCE_ALL_DATA_REQUEST: 'GET_MAINTAIN_REFERENCE_ALL_DATA_REQUEST',
  GET_MAINTAIN_REFERENCE_ALL_DATA_SUCCESS: 'GET_MAINTAIN_REFERENCE_ALL_DATA_SUCCESS',
  GET_MAINTAIN_REFERENCE_ALL_DATA_FAILURE: 'GET_MAINTAIN_REFERENCE_ALL_DATA_FAILURE',

  POST_MAINTAIN_REFERENCE_DATA_REQUEST: 'POST_MAINTAIN_REFERENCE_DATA_REQUEST',
  POST_MAINTAIN_REFERENCE_DATA_SUCCESS: 'POST_MAINTAIN_REFERENCE_DATA_SUCCESS',
  POST_MAINTAIN_REFERENCE_DATA_FAILURE: 'POST_MAINTAIN_REFERENCE_DATA_FAILURE',

  GET_MAINTAIN_MASTER_REFERENCE_DATA_REQUEST: 'GET_MAINTAIN_MASTER_REFERENCE_DATA_REQUEST',
  GET_MAINTAIN_MASTER_REFERENCE_DATA_SUCCESS: 'GET_MAINTAIN_MASTER_REFERENCE_DATA_SUCCESS',
  GET_MAINTAIN_MASTER_REFERENCE_DATA_FAILURE: 'GET_MAINTAIN_MASTER_REFERENCE_DATA_FAILURE',
}
