export const stampDutyConstants = {
  GET_STAMPDUTY_REQUEST: 'GET_STAMPDUTY_REQUEST',
  GET_STAMPDUTY_SUCCESS: 'GET_STAMPDUTY_SUCCESS',
  GET_STAMPDUTY_FAILURE: 'GET_STAMPDUTY_FAILURE',

  GET_LOCATION_REQUEST: 'GET_LOCATION_REQUEST',
  GET_LOCATION_SUCCESS: 'GET_LOCATION_SUCCESS',
  GET_LOCATION_FAILURE: 'GET_LOCATION_FAILURE',

  GET_MONTH_REQUEST: 'GET_MONTH_REQUEST',
  GET_MONTH_SUCCESS: 'GET_MONTH_SUCCESS',
  GET_MONTH_FAILURE: 'GET_MONTH_FAILURE',
}
