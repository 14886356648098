export const cageConstants = {
  DELETE_CAGE_REQUEST: 'DELETE_CAGE_REQUEST',
  DELETE_CAGE_SUCCESS: 'DELETE_CAGE_SUCCESS',
  DELETE_CAGE_FAILURE: 'DELETE_CAGE_FAILURE',

  POST_CAGE_REQUEST: 'POST_CAGE_REQUEST',
  POST_CAGE_SUCCESS: 'POST_CAGE_SUCCESS',
  POST_CAGE_FAILURE: 'POST_CAGE_FAILURE',

  GET_CAGE_REQUEST: 'GET_CAGE_REQUEST',
  GET_CAGE_SUCCESS: 'GET_CAGE_SUCCESS',
  GET_CAGE_FAILURE: 'GET_CAGE_FAILURE',

  GET_ALL_CAGE_REQUEST: ' GET_ALL_CAGE_REQUEST',
  GET_ALL_CAGE_SUCCESS: 'GET_ALL_CAGE_SUCCESS',
  GET_ALL_CAGE_FAILURE: 'GET_ALL_CAGE_FAILURE',
}
