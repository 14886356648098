export const bagByBagStatusReportConstants = {
  GET_BAG_BY_BAG_STATUS_REPORT_REQUEST: 'GET_BAG_BY_BAG_STATUS_REPORT_REQUEST',
  GET_BAG_BY_BAG_STATUS_REPORT_SUCCESS: 'GET_BAG_BY_BAG_STATUS_REPORT_SUCCESS',
  GET_BAG_BY_BAG_STATUS_REPORT_FAILURE: 'GET_BAG_BY_BAG_STATUS_REPORT_FAILURE',

  GET_BAG_STATUS_REQUEST: 'GET_BAG_STATUS_REQUEST',
  GET_BAG_STATUS_SUCCESS: 'GET_BAG_STATUS_SUCCESS',
  GET_BAG_STATUS_FAILURE: 'GET_BAG_STATUS_FAILURE',

  bagByBagStatusReportFormValue: 'bagByBagStatusReportFormValue',
}
