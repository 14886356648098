import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux'
import { Row, Col, Button } from 'react-bootstrap'
import { calcDate } from 'constants/dateTime'
import 'bootstrap/dist/css/bootstrap.min.css'
import { useTranslation } from 'react-i18next'
import Loader from 'react-loader-spinner'
import { carrierActions } from 'actions/carrier.actions'
import { EXCEPT_ALPHANUMERIC, EXCEPT_NUMBER } from 'constants/regex'
import DatePicker from 'react-datepicker'
import { Dropdown } from 'components/UI/Input'
import Moment from 'moment'

function bulkUpdate(props) {
  const { list } = props

  const [air, setAir] = useState('')
  const [pick, setPick] = useState('')
  const [loading, setLoading] = useState(false)

  const [timeErr, setTimeErr] = useState(null)
  const [timeErr1, setTimeErr1] = useState(null)

  const [pickupLocation1, setPickupLocation1] = useState(null)
  const [sStatus, setSStatus] = useState(null)

  const [save, setSave] = useState(false)
  const [hours, setHours] = useState(null)
  const [mins, setMins] = useState(null)
  const [shours, setShours] = useState(null)
  const [smins, setSmins] = useState(null)
  const [schedule, setSchedule] = useState(null)

  const [originAirport1, setOriginAirport1] = useState(null)
  const [destinationAirport1, setDestinationAirport1] = useState(null)

  const [description, setDescription] = useState(null)
  const { t } = useTranslation()

  const onChangeDescription = object => {
    const { value } = object.target

    setDescription(value.replace(EXCEPT_ALPHANUMERIC, ''))
    // for showing the email confirmation text
  }

  useEffect(() => {
    if (props.getStatus && props.getStatus.result) {
      setLoading(false)
    } else if (props.getStatus && !props.getStatus.result) {
      setLoading(false)
    }
  }, [props.getStatus])

  const handleChange = e => {
    let a = e.target.value.substring(0, 2)
    if (a !== null && a > 23) {
      a = null
    }

    setHours(a)
  }
  const handleChange1 = e => {
    let a = e.target.value.substring(0, 2)
    if (a !== null && a > 59) {
      a = null
    }
    setMins(a)
  }
  const handleChange3 = e => {
    let a = e.target.value.substring(0, 2)
    if (a !== null && a > 23) {
      a = null
    }

    setShours(a)
  }

  const handleChange4 = e => {
    let a = e.target.value.substring(0, 2)
    if (a !== null && a > 59) {
      a = null
    }

    setSmins(a)
  }
  useEffect(() => {
    if (props.getRefdata) {
      let a = props.getRefdata.result.map(val => {
        return val
      })
      setAir(a.filter(a => a.refMaster === 'Airport'))

      setPick(a.filter(a => a.refMaster === 'Pick up Location'))
    }
  }, [props.getRefdata])
  useEffect(() => {
    if (shours !== null && shours !== '' && (smins === null || smins === '')) {
      setTimeErr(t('Please_Enter_A_Valid_Time_Validation_Msg_label'))
    } else if (smins !== null && smins !== '' && (shours === null || shours === '')) {
      setTimeErr(t('Please_Enter_A_Valid_Time_Validation_Msg_label'))
    } else {
      setTimeErr(null)
    }
  })

  const onChangeOriginAirport = selectedOption => {
    setOriginAirport1(selectedOption)
  }
  const onChangeDestinationAirport = selectedOption => {
    setDestinationAirport1(selectedOption)
  }
  const onChangePickupLocation = selectedOption => {
    setPickupLocation1(selectedOption)
  }
  const onStatus = object => {
    setSStatus(object)
  }

  const update = () => {
    let li = {}
    li.identityCarrierList = list.identityCarrierList
    li.idList = list.idList
    if (pickupLocation1 !== null && pickupLocation1 !== '') {
      li.pickupLocationRefMaster = pickupLocation1.refMaster
      li.pickupLocationRefCode = pickupLocation1.refCode
    }
    if (destinationAirport1 !== null && destinationAirport1 !== '') {
      li.destAirportRefMaster = destinationAirport1.refMaster
      li.destAirportRefCode = destinationAirport1.refCode
    }
    if (schedule !== null && schedule !== '') {
      li.scheduleFlag = schedule
    }
    if (shours !== null && shours !== '' && smins !== null && smins !== '') {
      li.sellingCutoffTm = String(shours).padStart(2, '0') + ':' + String(smins).padStart(2, '0')
    }
    if (sStatus !== null && sStatus !== '') {
      li.status = sStatus.statusDesc
    }
    if (originAirport1 !== null && originAirport1 !== '') {
      li.originAirportRefMaster = originAirport1.refMaster
      li.originAirportRefCode = originAirport1.refCode
    }
    if (description !== null && description !== '') {
      li.vesselName = description
    }

    if (hours !== null && hours !== '') {
      li.departureFromTm = String(hours).padStart(2, '0') + ':' + String(mins).padStart(2, '0')
    }
    props.makeCancel(li)
  }
  useEffect(() => {
    if (hours !== null && hours !== '' && (mins === null || mins === '')) {
      setTimeErr1(t('Please_Enter_A_Valid_Time_Validation_Msg_label'))
    } else if (mins !== null && mins !== '' && (hours === null || hours === '')) {
      setTimeErr1(t('Please_Enter_A_Valid_Time_Validation_Msg_label'))
    } else {
      setTimeErr1(null)
    }
  })

  return (
    <div>
      {loading === true ? (
        <div style={{ textAlign: 'center' }} className='mt-5'>
          <Loader type='ThreeDots' color='#bf2e1a' height={60} visible={loading} width={60} />
        </div>
      ) : (
        <div className='container bg-white'>
          <div>
            <Row>
              <Col xs='8'>
                <label className='form-label'>{t('Departure_Time_label')}</label>

                <br />
                <Row>
                  <Col xs='4'>
                    <input
                      type='number'
                      name='hours'
                      className='form-control'
                      placeholder='HH'
                      min={0}
                      max={23}
                      value={hours}
                      onChange={e => handleChange(e)}
                    />
                  </Col>

                  <Col xs='5'>
                    <input
                      type='number'
                      className='form-control'
                      placeholder='MM'
                      name='mins'
                      min={0}
                      max={59}
                      value={mins}
                      onChange={e => handleChange1(e)}
                    />
                  </Col>
                  <div>
                    {' '}
                    {timeErr1 !== null ? (
                      <span className='form-label ml-3' style={{ color: '#B13C27' }}>
                        {timeErr1}
                      </span>
                    ) : null}
                  </div>
                </Row>
              </Col>
            </Row>

            <Row className='mt-4'>
              {/* <Col xs='4'>
                <label className='form-label'>{t('Origin_label')}</label>
                <span className='astrick'>*</span>
                <br />
                <Dropdown
                  closeMenuOnSelect={true}
                  className='form-control mb-4 '
                           isClearable
                  value={originAirport1}
                  placeholder={t('TEXT_SELECT_label')}
                  isDisabled={save}
                  onChange={onChangeOriginAirport}
                  options={air}
                  optionLabel='refValue'
                  optionValue='refValue'
                />
              </Col> */}

              <Col xs='6'>
                <label className='form-label'>{t('Destination_label')}</label>
                <br />{' '}
                <Dropdown
                  closeMenuOnSelect={true}
                  className='form-control mb-4 '
                  value={destinationAirport1}
                  placeholder={t('TEXT_SELECT_label')}
                  isDisabled={save}
                  isClearable
                  onChange={onChangeDestinationAirport}
                  options={air}
                  optionLabel='refValue'
                  optionValue='refValue'
                />
              </Col>
            </Row>
            {/* <Row className='mt-4'>
              {' '}
              <Col xs='12'>
                <label className='form-label'>{t('Vessel_Description_label')}</label>
                <br />{' '}
                <input
                  type='text'
                  name='description'
                  disabled={save}
                  autoComplete='off'
                  onChange={onChangeDescription}
                  value={description}
                     
                  maxLength={25}
                  placeholder={t('Vessel_Description_label')}
                  className='form-control'
                ></input>
                {description !== '' ? (
                  <span className='form-label' style={{ color: '#B13C27' }}>
                    Maximum length 25
                  </span>
                ) : null}
              </Col>{' '}
            </Row> */}
            <Row className='mt-4'>
              <Col xs='6'>
                <label className='form-label'>{t('Carrier_Status_label')}</label>
                <br />{' '}
                <Dropdown
                  closeMenuOnSelect={true}
                  className='form-control mb-4 '
                  value={sStatus}
                  onChange={onStatus}
                  isClearable
                  options={props.getCarrierStatus && props.getCarrierStatus.result.map(val => val)}
                  optionLabel='statusDesc'
                  optionValue='statusDesc'
                />
              </Col>{' '}
              <Col xs='6'>
                <label className='form-label'>{t('Scheduled/Unscheduled_label')}</label>
                <br />{' '}
                <select
                  value={schedule}
                  className='form-control'
                  onChange={event => setSchedule(event.target.value)}
                >
                  <option value={''}>Select</option>
                  <option value={'Scheduled'}>Scheduled</option>
                  <option value={'UnScheduled'}>UnScheduled</option>
                </select>
              </Col>{' '}
            </Row>
            <Row className='mt-4'>
              <Col xs='5'>
                <label className='form-label'>{t('Pickup_Location_label')}</label>
                <br />{' '}
                <Dropdown
                  closeMenuOnSelect={true}
                  className='form-control mb-4 '
                  isClearable
                  value={pickupLocation1}
                  isDisabled={save}
                  placeholder={t('TEXT_SELECT_label')}
                  onChange={onChangePickupLocation}
                  options={pick}
                  optionLabel='refValue'
                  optionValue='refValue'
                />
              </Col>{' '}
              <Col xs='6'>
                <label className='form-label'>{t('Selling_Cut-Off_Time_label')}</label>
                <br />{' '}
                <Row>
                  <Col>
                    {' '}
                    <input
                      type='number'
                      name='shours'
                      className='form-control'
                      placeholder='HH'
                      min={0}
                      max={23}
                      value={shours}
                      onChange={e => handleChange3(e)}
                    />
                  </Col>
                  <Col>
                    <input
                      type='number'
                      className='form-control'
                      placeholder='MM'
                      name='smins'
                      min={0}
                      max={59}
                      value={smins}
                      onChange={e => handleChange4(e)}
                    />
                  </Col>
                </Row>
                <div>
                  {' '}
                  {timeErr !== null ? (
                    <span className='form-label' style={{ color: '#B13C27' }}>
                      {timeErr}
                    </span>
                  ) : null}
                </div>
              </Col>{' '}
            </Row>
            <br />
            <hr />
            <Row className='text-center mr-3'>
              <Col>
                {' '}
                <button
                  onClick={() => update()}
                  className='btn btn-primary'
                  disabled={
                    !(
                      ((shours && smins) ||
                        mins ||
                        hours ||
                        sStatus ||
                        pickupLocation1 ||
                        originAirport1 ||
                        destinationAirport1 ||
                        description) &&
                      !timeErr1 &&
                      !timeErr
                    )
                  }
                >
                  {t('update_label')}
                </button>
              </Col>
            </Row>
          </div>
        </div>
      )}
    </div>
  )
}

function mapState(state) {
  return {
    getStatus: state.postCage.getData,
    getRefdata: state.getRefAll.getData,
    getCarrierStatus: state.getCarrierStatus.getData,
  }
}

const actionCreators = {
  makeCancel: carrierActions.cancelCarrier,
}

export default connect(mapState, actionCreators)(bulkUpdate)
